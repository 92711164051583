<template>
  <Dialog
    v-model:visible="modalPublicar"
    :breakpoints="{'960px': '75vw', '640px': '90vw'}"
    :style="{width: '40vw'}"
    :modal="true"
    :closable="false"
  >
    <template #header>
      <div class="flex justify-center items-center w-full gap-2">
        <i class="pi pi-book font-bold"/>
        <span class="font-bold text-2xl">Publicar Solicitud</span>
      </div>
    </template>
    <section class="flex flex-col gap-4 w-full p-4 items-center">
      <div class="flex flex-col gap-4 w-1/2 items-center">
        <div class="flex flex-col gap-2 w-full">
          <label>Tipo de procesos</label>
          <AutoComplete
            v-model="values.tipoProceso"
            :suggestions="tiposProcesos"
            @complete="buscar($event, 1)"
            class="p-inputtext-xs"
            inputClass="w-full"
            field="nombre"
            @item-select="onChangeTipoProceso"
            placeholder="Digite el tipo de proceso"
          />
          <span class="text-xs text-red-500" v-if="errors.tipoProceso">{{ errors.tipoProceso }}</span>
        </div>

        <div class="flex flex-col gap-2 w-full">
          <label>Procesos</label>
          <AutoComplete
            v-model="values.proceso"
            :suggestions="procesos"
            @complete="buscar($event, 2)"
            class="p-inputtext-xs"
            inputClass="w-full"
            field="nombre"
            @item-select="onChangeProceso"
            placeholder="Digite el proceso"
          />
          <span class="text-xs text-red-500" v-if="errors.proceso">{{ errors.proceso }}</span>
        </div>

        <div class="flex flex-col gap-2 w-full">
          <label>Carpetas</label>
          <AutoComplete
            v-model="values.carpeta"
            :suggestions="carpetas"
            @complete="buscar($event, 3)"
            class="p-inputtext-xs"
            inputClass="w-full"
            field="nombre"
            @item-select="onChangeCarpeta"
            placeholder="Digite la carpeta"
          />
          <span class="text-xs text-red-500" v-if="errors.carpeta">{{ errors.carpeta }}</span>
        </div>

        <div v-if="values.subcarpeta" class="flex flex-col gap-2 w-full">
          <label>SubCarpetas</label>
          <AutoComplete
            v-model="values.subcarpeta"
            :suggestions="subcarpetas"
            @complete="buscar($event, 5)"
            class="p-inputtext-xs"
            inputClass="w-full"
            field="nombre"
            @item-select="onChangeSubCarpeta"
            placeholder="Digite la carpeta"
          />
          <span class="text-xs text-red-500" v-if="errors.carpeta">{{ errors.carpeta }}</span>
        </div>

        <div class="flex flex-col gap-2 w-full">
          <label>Código</label>
          <InputText
            v-model.trim="values.codigo"
            required="true"
            class="p-inputtext-xs p-mt-1"
          />
          <span class="text-xs text-red-500" v-if="errors.codigo">{{ errors.codigo }}</span>
        </div>

        <div class="flex flex-col gap-2 w-full">
          <label>Documentos</label>
          <AutoComplete
            v-model="values.documento"
            :suggestions="documentos"
            @complete="buscar($event, 4)"
            class="p-inputtext-xs"
            inputClass="w-full"
            field="nombre"
            @item-select="onChangeDocumento"
            :disabled="obj.carpeta.nombre === ''"
            placeholder="Digite el documento"
          />
          <span class="text-xs text-red-500" v-if="errors.documento">{{ errors.documento }}</span>
        </div>

<!--        <div class="flex flex-col gap-2 w-full">-->
<!--          <label>Area</label>-->
<!--          <MultiSelect-->
<!--            v-model="values.areas"-->
<!--            :options="areas"-->
<!--            key="id"-->
<!--            optionLabel="name"-->
<!--            optionValue="id"-->
<!--            placeholder="Seleccione un area"-->
<!--            display="chip"-->
<!--            class="multiselect-custom p-inputtext-xs text-xs"-->
<!--          />-->
<!--          <span class="text-red-500 text-xs">{{ errors.areas }}</span>-->
<!--        </div>-->

      </div>
    </section>
    <template #footer>
      <div class="flex justify-center">
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-outlined"
          @click="actionModal"
        />
        <Button
          label="Publicar"
          icon="pi pi-check"
          @click="publicar"
        />
      </div>
    </template>
  </Dialog>
</template>

<script>
import { onMounted, computed, ref } from 'vue'
import store from '../store'
import * as yup from 'yup'
import { useField, useForm } from 'vee-validate'
import ModalSolicitudesService from '@/apps/pharmasan/calidad/views/mapa/services/modalSolicitudes.service'

export default {
  name: 'modalPublicar',
  setup () {
    // Computed
    const modalPublicar = computed(() => store.getters.getModalPublicar)
    const getInfoPublicar = computed(() => store.getters.getInfoPublicar)
    const ruta = computed(() => {
      if (getInfoPublicar.value.ruta) {
        return getInfoPublicar.value.ruta.split(' > ')
      } else {
        return []
      }
    })

    // Referencias
    const tiposProcesos = ref([])
    const procesos = ref([])
    const carpetas = ref([])
    const subcarpetas = ref([])
    const documentos = ref([])
    const _modalSolicitudesService = new ModalSolicitudesService()
    const areas = ref([])
    const dataModal = {}

    // Services

    // Data
    const obj = ref({
      tipoProceso: {
        id: null,
        nombre: ''
      },
      proceso: {
        id: null,
        nombre: ''
      },
      carpeta: {
        id: null,
        nombre: ''
      },
      subcarpeta: {
        id: null,
        nombre: ''
      },
      codigo: '',
      documento: {
        id: null,
        nombre: ''
      }
    })
    const schema = yup.object({
      areas: yup.array().nullable().required('El area es requerida'),
      tipoProceso: yup.string().nullable().required('El tipo de proceso es requerido').label(''),
      proceso: yup.string().nullable().required('El proceso es requerido').label(''),
      carpeta: yup.string().nullable().required('La carpeta es requerida').label(''),
      codigo: yup.string().nullable().required('El código es requerido').label(''),
      documento: yup.string().nullable().required('El documento es requerido').label('')
    })
    const { errors, values, handleSubmit, handleReset } = useForm({
      validationSchema: schema
    })
    useField('areas', null, { initialValue: null })
    useField('tipoProceso', null, { initialValue: null })
    useField('proceso', null, { initialValue: null })
    useField('carpeta', null, { initialValue: null })
    useField('codigo', null, { initialValue: null })
    useField('documento', null, { initialValue: null })

    // Computed

    // Methods
    const actionModal = () => {
      store.dispatch('actionModalPublicar')
    }

    const publicar = handleSubmit((values) => {
      var objpublicar = {
        ruta: `${obj.value.tipoProceso.nombre} > ${obj.value.proceso.nombre} > ${obj.value.carpeta.nombre} > ${obj.value.documento.nombre}`,
        estado: dataModal.value.estado,
        respuesta: dataModal.value.respuesta,
        solicitudId: dataModal.value.solicitudId,
        tipo: dataModal.value.tipo
        // areas: values.areas
      }
      if (values.subcarpeta) {
        objpublicar.ruta = `${obj.value.tipoProceso.nombre} > ${obj.value.proceso.nombre} > ${obj.value.carpeta.nombre} > ${obj.value.subcarpeta.nombre} > ${obj.value.documento.nombre}`
      }
      _modalSolicitudesService.publicarSolicitud(objpublicar).then(() => {
        actionModal()
        handleReset()
        store.dispatch('getSolicitudes', {
          limit: 10,
          offset: 0
        })
      })
    })
    const onChangeTipoProceso = (event) => {
      values.tipoProceso = event.value.nombre
      obj.value.tipoProceso = {
        id: event.value.id,
        nombre: event.value.nombre
      }
    }
    const onChangeProceso = (event) => {
      values.proceso = event.value.nombre
      obj.value.proceso = {
        id: event.value.id,
        nombre: event.value.nombre
      }
    }
    const onChangeCarpeta = (event) => {
      values.carpeta = event.value.nombre
      obj.value.carpeta = {
        id: event.value.id,
        nombre: event.value.nombre
      }
    }
    const onChangeSubCarpeta = (event) => {
      values.subcarpeta = event.value.nombre
      obj.value.subcarpeta = {
        id: event.value.id,
        nombre: event.value.nombre
      }
    }
    const onChangeDocumento = (event) => {
      values.documento = event.value.nombre
      obj.value.documento = {
        id: event.value.id,
        nombre: event.value.nombre
      }
    }
    const setData = (data) => {
      dataModal.value = data
      data.ruta = data.ruta.split(' > ')
      values.tipoProceso = data.ruta[0]
      values.proceso = data.ruta[1]
      values.carpeta = data.ruta[2]
      if (data.ruta[4]) {
        values.subcarpeta = data.ruta[3]
        values.documento = data.ruta[4]
      } else {
        values.documento = data.ruta[3]
      }
      obj.value = {
        tipoProceso: {
          id: null,
          nombre: data.ruta[0]
        },
        proceso: {
          id: null,
          nombre: data.ruta[1]
        },
        carpeta: {
          id: null,
          nombre: data.ruta[2]
        },
        subcarpeta: {
          id: null,
          nombre: ''
        },
        documento: {
          id: null,
          nombre: data.ruta[3]
        }
      }
      if (data.ruta[4]) {
        obj.value.subcarpeta = {
          id: null,
          nombre: data.ruta[3]
        }
        obj.value.documento = {
          id: null,
          nombre: data.ruta[4]
        }
      } else {
        obj.value.documento = {
          id: null,
          nombre: data.ruta[3]
        }
      }
      values.areas = data.areas.map(area => area.areaId)
    }

    const buscar = (event, tipoId) => {
      // Tipo 1 = Tipo de proceso, 2 = Proceso, 3 = Carpeta, 4 = Documento
      switch (tipoId) {
        case 1:
          _modalSolicitudesService.getTiposProcesos({ nombre: event.query }).then((res) => {
            tiposProcesos.value = res.data
            values.tipoProceso = event.query
            obj.value.tipoProceso = {
              id: null,
              nombre: event.query
            }
          })
          break
        case 2:
          _modalSolicitudesService.getProcesos({
            nombre: event.query,
            padre: obj.value.tipoProceso.id
          }).then((res) => {
            procesos.value = res.data
            values.proceso = event.query
            obj.value.proceso = {
              id: null,
              nombre: event.query
            }
          })
          break
        case 3:
          _modalSolicitudesService.getCarpetas({
            nombre: event.query,
            padre: obj.value.proceso.id
          }).then((res) => {
            carpetas.value = res.data
            values.carpeta = event.query
            obj.value.carpeta = {
              id: null,
              nombre: event.query
            }
          })
          break
        case 4:
          values.documento = event.query
          obj.value.documento = {
            id: null,
            nombre: event.query
          }
          break
          case 5:
          _modalSolicitudesService.getCarpetas({
            nombre: event.query,
            padre: obj.value.proceso.id
          }).then((res) => {
            subcarpetas.value = res.data
            values.subcarpeta = event.query
            obj.value.subcarpeta = {
              id: null,
              nombre: event.query
            }
          })
          break
      }
    }

    // Watchers

    // OnMounted
    onMounted(() => {
      _modalSolicitudesService.getAreasList().then(({ data }) => {
        areas.value = data
        // values.areas = getInfoPublicar.value.areas.map(area => area.areaId)
      })
    })

    return {
      actionModal,
      modalPublicar,
      errors,
      values,
      publicar,
      obj,
      getInfoPublicar,
      tiposProcesos,
      procesos,
      carpetas,
      subcarpetas,
      documentos,
      areas,
      onChangeTipoProceso,
      onChangeProceso,
      onChangeCarpeta,
      onChangeSubCarpeta,
      onChangeDocumento,
      setData,
      ruta,
      buscar
    }
  }
}
</script>
