<template>
  <Dialog
    header="Solicitud de creación o modificación de documentos"
    v-model:visible="modalSolicitudes"
    :breakpoints="{'960px': '75vw', '640px': '90vw'}"
    :style="{ width: tamanoModal, transition: 'all 0.5s ease-in-out', 'z-index': 50 }"
    :modal="true"
  >
    <div class="flex gap-2">
      <section :class="`flex flex-col gap-4 ${tamanoForm}`">
        <div class="flex flex-col gap-2 w-full">
          <label>Tipo de solicitud</label>
          <Dropdown
            v-model="values.tipo"
            :options="tiposSolicitud"
            optionLabel="label"
            optionValue="value"
            placeholder="Seleccione una opción"
            class="p-inputtext-xs"
            @change="onChangeTipoSolicitud"
          />
        </div>
        <div class="flex justify-around items-center">
          <div class="flex items-center gap-2">
            <RadioButton value="1" v-model="values.opcionProceso"/>
            <label for="city1">Tipo Proceso</label>
          </div>
          <div class="flex items-center gap-2">
            <RadioButton id="city2" name="city" value="2" v-model="values.opcionProceso"/>
            <label for="city2">Proceso</label>
          </div>
          <div class="flex items-center gap-2">
            <RadioButton id="city3" name="city" value="3" v-model="values.opcionProceso"/>
            <label for="city3">Carpeta</label>
          </div>
          <div class="flex items-center gap-2">
            <RadioButton id="city4" name="city" value="4" v-model="values.opcionProceso"/>
            <label for="city4">Documento</label>
          </div>
        </div>
        <div class="flex flex-col gap-4" v-if="values.opcionProceso > 0">
          <div class="flex gap-4">
            <div class="flex flex-col gap-2 w-1/2" v-if="values.opcionProceso > 0">
              <label>Tipo de procesos</label>
              <AutoComplete
                v-model="values.tipoProceso"
                :suggestions="tiposProcesos"
                @complete="buscar($event, 1)"
                class="p-inputtext-xs"
                inputClass="w-full"
                field="nombre"
                @item-select="onChangeTipoProceso"
                placeholder="Digite el tipo de proceso"
                :forceSelection="values.tipo !== 1"
              />
              <span class="text-xs text-red-500" v-if="errors.tipoProceso">{{ errors.tipoProceso }}</span>
            </div>
            <div class="flex flex-col gap-2 w-1/2" v-if="values.opcionProceso > 1">
              <label>Procesos</label>
              <AutoComplete
                v-model="values.proceso"
                :suggestions="procesos"
                @complete="buscar($event, 2)"
                class="p-inputtext-xs"
                inputClass="w-full"
                field="nombre"
                @item-select="onChangeProceso"
                :disabled="obj.tipoProceso.nombre === ''"
                placeholder="Digite el proceso"
                :forceSelection="values.tipo !== 1"
              />
              <span class="text-xs text-red-500" v-if="errors.proceso">{{ errors.proceso }}</span>
            </div>
          </div>
          <div class="flex gap-4">
            <div class="flex flex-col gap-2 w-1/2" v-if="values.opcionProceso > 2">
              <label>Carpetas</label>
              <AutoComplete
                v-model="values.carpeta"
                :suggestions="carpetas"
                @complete="buscar($event, 3)"
                class="p-inputtext-xs"
                inputClass="w-full"
                field="nombre"
                @item-select="onChangeCarpeta"
                :disabled="obj.proceso.nombre === ''"
                placeholder="Digite la carpeta"
                :forceSelection="values.tipo !== 1"
              />
              <span class="text-xs text-red-500" v-if="errors.carpeta">{{ errors.carpeta }}</span>
            </div>
            <div class="flex flex-col gap-2 w-1/2" v-if="values.opcionProceso > 3">
              <label>Documentos</label>
              <AutoComplete
                v-model="values.documento"
                :suggestions="documentos"
                @complete="buscar($event, 4)"
                class="p-inputtext-xs"
                inputClass="w-full"
                field="nombre"
                @item-select="onChangeDocumento"
                :disabled="obj.carpeta.nombre === ''"
                placeholder="Digite el documento"
                :forceSelection="values.tipo !== 1"
              />
              <span class="text-xs text-red-500" v-if="errors.documento">{{ errors.documento }}</span>
            </div>
          </div>
        </div>
        <div class="flex flex-col gap-2 w-full">
          <label>Titulo</label>
          <InputText
            v-model="values.asunto"
            class="p-inputtext-xs"
            placeholder="Escriba el titulo de la solicitud"
          />
          <span class="text-red-500 text-xs">{{ errors.asunto }}</span>
        </div>
        <div class="flex flex-col gap-2 w-full">
          <label>Solicitud</label>
          <Textarea
            v-model="values.solicitud"
            rows="3"
            cols="30"
            class="p-inputtext-xs"
            placeholder="Escriba la solicitud"
          />
          <span class="text-red-500 text-xs">{{ errors.solicitud }}</span>
        </div>
      </section>

      <section class="flex w-2/3 w-editor" v-if="showEditor">
        <Editor
          ref="editor"
          api-key="bk8rtsyvxlv71yhl5xdunqtik4gffjibzq2u0sh7iofxjvvz"
          :init="{
            plugins: 'lists link image   table',
            language: 'es'
          }"
          v-model="dataEditor"
          class="w-full"
        />
      </section>
    </div>

    <template #footer>
      <Button label="Cancelar" icon="pi pi-times" @click="closeModal" class="p-button-text"/>
      <Button label="Crear" icon="pi pi-check" @click="onSubmit" autofocus/>
    </template>
  </Dialog>
</template>

<script>
import { ref, onMounted, computed, watch } from 'vue'
import * as yup from 'yup'
import { useField, useForm } from 'vee-validate'
import ModalSolicitudesService from '@/apps/pharmasan/calidad/views/mapa/services/modalSolicitudes.service'
import Swal from 'sweetalert2'
import Editor from '@tinymce/tinymce-vue'

export default {
  name: 'modalSolicitudes',
  components: {
    Editor
  },
  setup (props, { emit }) {
    // Computed
    const ruta = computed(() => {
      if (obj.value.tipoProceso.nombre !== '') {
        let ruta = ''
        for (const [index, item] of Object.entries(obj.value)) {
          if (index === 'tipoProceso') {
            if (item.nombre !== '') {
              ruta += `${item.nombre}`
            }
          } else {
            if (item.nombre !== '') {
              ruta += ` > ${item.nombre}`
            }
          }
        }
        return ruta
      } else {
        return 'null'
      }
    })

    // Referencias
    const fileUploadSolicitud = ref()
    const fileUploadDocumentacion = ref()

    // Services
    const _modalSolicitudesService = new ModalSolicitudesService()

    // Data
    const showEditor = ref(false)
    const tamanoModal = ref('40vw')
    const tamanoForm = ref('w-full')
    const dataEditor = ref('')
    const tiposProcesos = ref([])
    const procesos = ref([])
    const carpetas = ref([])
    const documentos = ref([])
    const obj = ref({
      tipoProceso: {
        id: null,
        nombre: ''
      },
      proceso: {
        id: null,
        nombre: ''
      },
      carpeta: {
        id: null,
        nombre: ''
      },
      documento: {
        id: null,
        nombre: ''
      }
    })
    const modalSolicitudes = ref(false)
    const schema = yup.object({
      tipo: yup.number().nullable().required('El tipo de solicitud es requerido'),
      asunto: yup.string().nullable().required('El titulo es requerido'),
      solicitud: yup.string().nullable().required('La solicitud es requerida'),
      opcionProceso: yup.number().nullable().required('La opción de proceso es requerida'),
      // idDocumento: yup.number().nullable().when('tipo', (val, schema) => {
      //   if (val === 2) {
      //     return yup.number().nullable().required('El documento es requerido').label('')
      //   } else {
      //     return yup.string().nullable(true)
      //   }
      // }),
      tipoProceso: yup.string().nullable().when('opcionProceso', (val, schema) => {
        if (val > 0) {
          return yup.string().nullable().required('El tipo de proceso es requerido').label('')
        } else {
          return yup.string().nullable(true)
        }
      }),
      proceso: yup.string().nullable().when('opcionProceso', (val, schema) => {
        if (val > 1) {
          return yup.string().nullable().required('El proceso es requerido').label('')
        } else {
          return yup.string().nullable(true)
        }
      }),
      carpeta: yup.string().nullable().when('opcionProceso', (val, schema) => {
        if (val > 2) {
          return yup.string().nullable().required('La carpeta es requerida').label('')
        } else {
          return yup.string().nullable(true)
        }
      }),
      documento: yup.string().nullable().when('opcionProceso', (val, schema) => {
        if (val > 3) {
          return yup.string().nullable().required('El documento es requerido').label('')
        } else {
          return yup.string().nullable(true)
        }
      })
    })
    const tiposSolicitud = [
      { label: 'Creación', value: 1 },
      { label: 'Modificación', value: 2 },
      { label: 'Inactivación', value: 3 }
    ]
    const { errors, values, handleSubmit, handleReset } = useForm({
      validationSchema: schema
    })
    useField('tipo', null, { initialValue: null })
    useField('asunto', null, { initialValue: null })
    useField('solicitud', null, { initialValue: null })
    useField('opcionProceso', null, { initialValue: null })
    useField('tipoProceso', null, { initialValue: null })
    useField('proceso', null, { initialValue: null })
    useField('carpeta', null, { initialValue: null })
    useField('documento', null, { initialValue: null })

    // Computed

    // Methods
    const clear = () => {
      handleReset()
      obj.value = {
        tipoProceso: {
          id: null,
          nombre: ''
        },
        proceso: {
          id: null,
          nombre: ''
        },
        carpeta: {
          id: null,
          nombre: ''
        },
        documento: {
          id: null,
          nombre: ''
        }
      }
    }
    const openModal = () => {
      modalSolicitudes.value = true
    }
    const closeModal = () => {
      modalSolicitudes.value = false
      emit('llamarFuncion')
    }
    const onSubmit = handleSubmit((values) => {
      if (!values) throw new Error('Error de validación')
      _modalSolicitudesService.crearSolicitud({
        tipo: values.tipo,
        solicitud: values.solicitud,
        ruta: ruta.value,
        asunto: values.asunto
      }).then((res) => {
        handleReset()
        closeModal()
        Swal.fire({
          icon: 'success',
          title: 'Solicitud creada',
          text: 'La solicitud se ha creado correctamente'
        })
      })
    })
    const onChangeTipoSolicitud = () => {
      const temp = values.tipo
      clear()
      values.tipo = temp
    }
    const onChangeTipoProceso = ({ value }) => {
      values.tipoProceso = value.nombre
      values.proceso = null
      values.carpeta = null
      values.documento = null
      obj.value = {
        tipoProceso: {
          id: value.id,
          nombre: value.nombre
        },
        proceso: {
          id: null,
          nombre: ''
        },
        carpeta: {
          id: null,
          nombre: ''
        },
        documento: {
          id: null,
          nombre: ''
        }
      }
    }
    const onChangeProceso = ({ value }) => {
      values.proceso = value.nombre
      values.carpeta = null
      values.documento = null
      obj.value = {
        tipoProceso: obj.value.tipoProceso,
        proceso: {
          id: value.id,
          nombre: value.nombre
        },
        carpeta: {
          id: null,
          nombre: ''
        },
        documento: {
          id: null,
          nombre: ''
        }
      }
    }
    const onChangeCarpeta = ({ value }) => {
      values.carpeta = value.nombre
      values.documento = null
      obj.value = {
        tipoProceso: obj.value.tipoProceso,
        proceso: obj.value.proceso,
        carpeta: {
          id: value.id,
          nombre: value.nombre
        },
        documento: {
          id: null,
          nombre: ''
        }
      }
    }
    const onChangeDocumento = ({ value }) => {
      values.documento = value.nombre
      obj.value = {
        tipoProceso: obj.value.tipoProceso,
        proceso: obj.value.proceso,
        carpeta: obj.value.carpeta,
        documento: {
          id: value.id,
          nombre: value.nombre
        }
      }
    }
    const buscar = (event, tipo) => {
      // Tipo 1 = Tipo de proceso, 2 = Proceso, 3 = Carpeta, 4 = Documento
      switch (tipo) {
        case 1:
          _modalSolicitudesService.getTiposProcesos({ nombre: event.query }).then((res) => {
            tiposProcesos.value = res.data
            if (values.tipo === 1) {
              values.tipoProceso = event.query
              obj.value.tipoProceso = {
                id: null,
                nombre: event.query
              }
            }
          })
          break
        case 2:
          _modalSolicitudesService.getProcesos({
            nombre: event.query,
            padre: obj.value.tipoProceso.id
          }).then((res) => {
            procesos.value = res.data
            if (values.tipo === 1) {
              values.proceso = event.query
              obj.value.proceso = {
                id: null,
                nombre: event.query
              }
            }
          })
          break
        case 3:
          _modalSolicitudesService.getCarpetas({
            nombre: event.query,
            padre: obj.value.proceso.id
          }).then((res) => {
            carpetas.value = res.data
            if (values.tipo === 1) {
              values.carpeta = event.query
              obj.value.carpeta = {
                id: null,
                nombre: event.query
              }
            }
          })
          break
        case 4:
          _modalSolicitudesService.getDocumentos({
            nombre: event.query,
            padre: obj.value.carpeta.id
          }).then((res) => {
            documentos.value = res.data
            if (values.tipo === 1) {
              values.documento = event.query
              obj.value.documento = {
                id: null,
                nombre: event.query
              }
            }
          })
          break
      }
    }

    // Watchers
    watch(() => obj.value.documento, (val) => {
      if (val.id || (val.nombre && values.tipo === 1)) {
        tamanoModal.value = '90vw'
        showEditor.value = true
        tamanoForm.value = 'w-1/3'
      } else {
        tamanoModal.value = '40vw'
        showEditor.value = false
        tamanoForm.value = 'w-full'
      }
    })

    // OnMounted
    onMounted(() => {

    })

    return {
      modalSolicitudes,
      openModal,
      closeModal,
      errors,
      values,
      onSubmit,
      tiposSolicitud,
      obj,
      tiposProcesos,
      procesos,
      carpetas,
      documentos,
      onChangeTipoProceso,
      onChangeProceso,
      onChangeCarpeta,
      onChangeDocumento,
      onChangeTipoSolicitud,
      fileUploadSolicitud,
      fileUploadDocumentacion,
      buscar,
      ruta,
      clear,
      dataEditor,
      tamanoModal,
      showEditor,
      tamanoForm
    }
  }
}
</script>

<style lang="scss">
.w-editor {
  .tox{
    width: 100% !important;
}
}
</style>
